export default {
  methods: {
    data() {
      return {
        patientIdHis: '',
        admissionNum: '',
        patientZyName: '',
        patientZySfzh: ''
      }
    },
    selectChange(item) {
      this.admissionNum = item.admissionNum
      this.patientZyName = item.patientZyName
      this.patientIdHis = item.patientIdHis
      this.patientZySfzh = item.patientZySfzh
      this.getData()
    }
  }
}
