<template>
  <div class="inpatient-deit">
    <van-form @submit="onSubmit">
      <van-field
        v-model="patientName"
        name="patientZyName"
        label="住院人"
        placeholder="请填写住院人"
        autocomplete="off"
        maxlength="20"
        :rules="[{ required: true }]"
      />
      <van-field
        v-model="admissionNum"
        type="number"
        name="admissionNum"
        label="住院号"
        maxlength="15"
        placeholder="请填写住院号"
        autocomplete="off"
        :rules="[{ required: true }]"
      />
      <van-field
        v-model="patientZySfzh"
        type="text"
        name="patientZySfzh"
        label="身份证号"
        placeholder="请填写身份证号"
        autocomplete="off"
        :rules="[{ required: true }]"
      />
      <van-field
        v-show="false"
        v-model="userId"
        name="userId"
      />
      <!-- <van-field
        v-show="false"
        v-model="id"
        name="id"
      /> -->
      <div v-if="showSumbit" style="margin: 16px;">
        <van-button round block type="info" native-type="submit" :loading="btnLoading" :disabled="btnLoading" loading-text="提交中...">提交</van-button>
      </div>
    </van-form>
  </div>
</template>
<script>
export default {
  name: 'InpatientEdit',
  data() {
    return {
      // id: '48',
      bindingStatus: 1,
      showSumbit: true,
      userId: '',
      isEdit: false,
      btnLoading: false,
      patientName: '',
      admissionNum: '',
      activeIndex: null,
      patientZySfzh: ''
    }
  },
  created() {
    const userInfo = this.$store.state.userInfo
    if (userInfo) {
      this.userId = userInfo.id
    }
    const id = this.$route.query.id
    if (id) {
      this.isEdit = true
      this.id = id
      this.getInfo()
    }
  },
  mounted() {},
  methods: {
    onSubmit(e) {
      const reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/
      if (reg.test(this.patientZySfzh) === false) {
        this.$toast.fail('身份证输入不合法')
        return false
      }
      this.btnLoading = true
      this.isEdit ? this.edit(e) : this.addInpatient(e)
    },

    addInpatient(data) {
      const dataa = {
        ...data,
        bindingStatus: 0,
      }
      this.$Apis.addPatientZyBinding(dataa).then(res => {
        this.btnLoading = false
        if (res.code === 200) {
          localStorage.patientZyName = data.patientZyName
          localStorage.admissionNum = data.admissionNum
          localStorage.patientIdHis = res.data.patientIdHis
          localStorage.patientZySfzh = res.data.patientZySfzh
          this.$toast.success('操作成功')
          setTimeout(() => {
            this.$router.go(-1)
          }, 300)
        } else {
          this.$toast(res.message || '暂无数据')
        }
      }).catch(() => {
        this.btnLoading = false
      })
    },
    edit(data) {
      this.$Apis.updPatientZyBinding(data).then(res => {
        this.btnLoading = false
        if (res.code === 200) {
          localStorage.patientZyName = data.patientZyName
          localStorage.admissionNum = data.admissionNum
          localStorage.patientIdHis = res.data.patientIdHis
          localStorage.patientZySfzh = res.data.patientZySfzh
          this.$toast.success('操作成功')
          setTimeout(() => {
            this.$router.go(-1)
          }, 300)
        } else {
          this.$toast(res.message || '暂无数据')
        }
      }).catch(() => {
        this.btnLoading = false
      })
    },
    getInfo() {
      this.$Apis.getPatientZyBindingByID(this.id).then(res => {
        if (res.code === 200) {
          this.admissionNum = res.data.admissionNum
          this.patientName = res.data.patientZyName
          this.patientZySfzh = res.data.patientZySfzh
          this.userId = res.data.userId
          this.showSumbit = true
        } else {
          this.showSumbit = false
          this.$dialog.confirm({
            title: '提示',
            message: res.message || '',
            showCancelButton: false
          })
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.inpatient-deit{
  padding: .1rem 0 0;
}
</style>
