<template>
  <div class="inpatient-information">住院人信息</div>
</template>
<script>
export default {
  name: '',
  data() {
    return {}
  },
  created() {},
  mounted() {},
  methods: {}
}
</script>
<style lang="less" scoped>
.inpatient-information{
  font-size: .16rem;
}
</style>
