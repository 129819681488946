import { render, staticRenderFns } from "./ZhuYuanBaoGaoRecordJY.vue?vue&type=template&id=e67fbfe4&scoped=true&"
import script from "./ZhuYuanBaoGaoRecordJY.vue?vue&type=script&lang=js&"
export * from "./ZhuYuanBaoGaoRecordJY.vue?vue&type=script&lang=js&"
import style0 from "./ZhuYuanBaoGaoRecordJY.vue?vue&type=style&index=0&id=e67fbfe4&rel=stylesheet%2Fless&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e67fbfe4",
  null
  
)

export default component.exports