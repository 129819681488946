<template>
  <!-- <div> -->
    <!-- <div v-if="loading" class="common-wrap">
      <van-loading size="24px" color="#0094ff" vertical>加载中...</van-loading>
    </div> -->
    <!-- <div v-else-if="!transportFail" class="baogao-jc-detail"> -->
    <div class="baogao-jc-detail">
      <div class="list">
        <div class="list-title">项目名称：</div>
        <div class="list-text">{{info.itemName || '——' }}</div>
      </div>
      <div class="list">
        <div class="list-title">检查日期：</div>
        <div class="list-text">{{info.reportdate || '——'}}</div>
      </div>
      <div class="list">
        <div class="list-title">检查结果：</div>
        <div class="list-text" />
      </div>
      <div class="list-box" v-html="info.jcMemory"></div>
      <!-- <div class="list">
        <div class="list-title">检查医生:</div>
        <div class="list-text">{{info.docName || '——'}}</div>
      </div> -->
      <!-- <div class="list">
        <div class="list-title">部位：</div>
        <div class="list-text">{{info.position}}</div>
      </div> -->
      <!-- <div class="list">
        <div class="list-title">检查描述:</div>
        <div class="list-text">{{info.reportDescribe || '——'}}</div>
      </div>
      <div class="list">
        <div class="list-title">检查诊断:</div>
        <div class="list-text">{{info.reportDiagnose || '——'}}</div>
      </div>
      <div class="list">
        <div class="list-title">检查建议:</div>
        <div class="list-text">{{info.reportAdvice || '——'}}</div>
      </div>
    </div> -->
    <!-- <div v-else class="common-wrap fail-wrap">
      数据获取失败,请稍后重试
    </div> -->
  </div>
</template>

<script>
export default {
  name: 'BaoGaoDetail',
  data() {
    return {
      info: {},
      isJudge: '',
      date: '', // 来自列表页的时间
    }
  },
  created() {
    const { date } = this.$route.query
    this.date = date || ''
    const info = localStorage.getItem('ZhuYuanBaoGaoRecord')
    if (info) {
      this.info = JSON.parse(info)
    }
  },
  mounted() {},
  methods: {},
  beforeRouteLeave(to, from, next) {
    to.query.isJudge = true
    to.query.date = this.date
    next();
  },
}
</script>
<style rel="stylesheet/less" lang="less" scoped>
.baogao-jc-detail{
  padding: .1rem .1rem 0;
  background: #fff;
  height: calc(100vh - .1rem);
  .list{
    font-size: .14rem;
    line-height: 1.5;
    padding: .1rem 0;
    &-title{
      float: left;
    }
    &-text{
      margin-left: .7rem;
    }
  }
  .list-box{
    margin-top: .2rem;
  }
}
.common-wrap{
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.fail-wrap{
  color: #0094ff;
}
</style>
