<template>
  <div class="zhuyuan-record">
    <div v-show="admissionNum">
      <SelectInpatient @selectChange="selectChange" />
      <noData v-show="!info.admissionNum && !loading" title="暂无数据" />
      <van-loading v-show="loading" class="loading" type="spinner" color="#1989fa" />
      <div v-show="!loading && info.admissionNum" class="zhuyuan-record-top">
        <!-- <div class="zhuyuan-record-pic">
          <img src="@/assets/images/touxiang.png" alt="" width="40" height="40" />
        </div>
        <div class="zhuyuan-record-text">
          <div class="zhuyuan-record-name">王小二</div>
          <div class="zhuyuan-record-num">住院号：000035202221</div>
        </div> -->
        <van-cell-group class="van-cell-box" inset>
          <van-cell title="病床号" :value="info.admissionNum" />
          <van-cell title="住院科室" :value="info.deptName" />
          <van-cell title="入院时间" :value="info.inDate" />
          <van-cell title="总费用" :value="'￥'+info.totalFee" />
          <van-cell title="余额" :value="'￥'+info.balance" />
          <van-cell title="人员类型" :value="info.perType" />
          <van-cell title="预交金" :value="'￥'+info.cadvPay" />
          <van-cell title="医保支付金额" :value="'￥'+info.medAnount" />
          <van-cell title="个人支付金额" :value="'￥'+info.indiviPay" />
          <van-cell title="欠费金额" :value="'￥'+info.arrears" />
        </van-cell-group>
      </div>
    </div>
    <div v-show="!admissionNum">
      <noData title="您还没有添加住院人员信息" />
      <div class="add-inpatient">
        <van-button class="btn" type="info" block @click="addInpatient">添加住院人员信息</van-button>
      </div>
    </div>
  </div>
</template>
<script>
import SelectInpatient from '@/components/selectInpatient'
import inpatientMinxins from './mixins'
export default {
  name: 'ZhuyuanRecord',
  components: {
    SelectInpatient
  },
  mixins: [inpatientMinxins],
  data() {
    return {
      admissionNum: '',
      patientZyName: '',
      loading: false,
      info: {},
      inpatientList: []
    }
  },
  created() {
    const userInfo = this.$store.state.userInfo
    if (userInfo) {
      this.id = userInfo.id
    }
  },
  mounted() {},
  methods: {
    getData() {
      if (!this.admissionNum) return
      const obj = {
        admissionNum: this.admissionNum,
        patientZyName: this.patientZyName
      }
      this.loading = true
      this.$Apis.getPatientZyInfo(obj).then(res => {
        this.loading = false
        if (res.code === 200) {
          this.info = res.data
        }
      }).catch(() => {
        this.loading = false
      })
    },
    addInpatient() {
      this.$router.push('/inpatient-edit')
    },
    getInpatientList() {
      this.$Apis.getPatientZyBindingList(this.id).then(res => {
        if (res.code === 200) {
          this.inpatientList = res.data
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.zhuyuan-record{
  .loading{
    width: 100%;
    height: calc(100vh - 2rem);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &-top{
    margin: .1rem 0;
    position: relative;
  }
  .van-cell-box{
    padding: .1rem 0;
  }
  &-pic{
    position: absolute;
    background: #fff;
    left: 50%;
    top: -.2rem;
    margin-left: -.2rem;
  }
  &-text{
    text-align: center;
    margin-bottom: .2rem;
    font-size: .16rem;
    line-height: .24rem;
  }
  .add-inpatient{
    margin: 0 .1rem;
    .btn{
      border-radius: .06rem;
    }
  }
}
</style>
