<template>
  <div class="baogao-detail">
    <table class="table">
      <tr class="bor">
        <!-- <th></th> -->
        <th>检验项目</th>
        <th>结果</th>
        <th>参考值</th>
        <th>单位</th>
      </tr>
      <tr v-for="item in info.list" :key="item.id" :class="item.flag === 1 ? 'redColor'
        : ''">
        <td>{{ item.chineseName }}</td>
        <td>{{ item.quantitativeResult }}</td>
        <td>{{ item.testItemReference }}</td>
        <td>{{ item.testItemUnit }}</td>
      </tr>
    </table>
    <!-- <div v-if="loading" class="loading-wrap">
      <van-loading size="24px" color="#0094ff" vertical>加载中...</van-loading>
    </div> -->
  </div>
</template>

<script>
export default {
  name: 'BaoGaoDetail',
  data() {
    return {
      info: {},
      date: '', // 来自列表页的时间
    }
  },
  created() {
    const { date } = this.$route.query
    this.date = date || ''
    const info = localStorage.getItem('ZhuYuanBaoGaoRecordJY')
    if (info) {
      this.info = JSON.parse(info)
    }
  },
  mounted() { },
  methods: {
  },
  beforeRouteLeave(to, from, next) {
    to.query.isJudge = true
    to.query.date = this.date
    next();
  },
}
</script>
<style rel="stylesheet/less" lang="less" scoped>
.redColor {
  color: red;
}

.baogao-detail {
  padding: .1rem .1rem 0;
  background: #fff;
  // height: calc(100vh - .1rem);

  .table {
    width: 100%;

    tr {
      border-bottom: 1px solid #ccc;
    }

    th {
      line-height: .3rem;
      font-size: .14rem;
    }

    td {
      max-width: 50px;
      word-wrap: break-word;
      line-height: .3rem;
      font-size: .12rem;
    }
  }
}

.loading-wrap {
  height: calc(100% - 60px);
  display: flex;
  justify-content: center;
  align-items: center;
}</style>
