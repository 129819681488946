<template>
  <div class="zhuyuanri-xiangqing">
    <SelectInpatient @selectChange="selectChange">
      <template #date>
        <div class="title" @click="showCalendar = true">{{ date }}<van-icon class="iconclass" name="play" /></div>
      </template>
    </SelectInpatient>

    <div v-show="list.length > 0 && !loading" class="bottom-box">
      <div class="list-box">
        <div v-for="(item, index) in list" :key="index" class="list-box-center">
          <div class="list-wrap">
            <div class="list-item flex4">项目名称</div>
            <div class="list-item flex2">单价(元)</div>
            <div class="list-item flex2">数量</div>
            <div class="list-item flex2">单位</div>
            <div class="list-item flex2">金额(元)</div>
          </div>
          <div v-for="(itemX, indexX) in item.reportItems" :key="indexX" class="list-wrap">
            <div class="list-item flex4">{{ itemX.itemName }}</div>
            <div class="list-item flex2">{{ itemX.price }}</div>
            <div class="list-item flex2">{{ itemX.num }}</div>
            <div class="list-item flex2">{{ itemX.specs }}</div>
            <div class="list-item  flex2">{{ itemX.cost }}</div>
          </div>
          <div class="bottom-title">
            <div></div>
            <div class="text-bottom">
              <span>{{ item.custItemName }}</span>
              <span class="price-size">￥{{ item.dayExpenditureMoney }}</span>
            </div>
          </div>

        </div>
      </div>
      <div class="text-box">
        <div></div>
        <div class="text">
          <span>日花费金额：</span>
          <span class="price">￥{{ dayExpenditureMoney }}</span>
        </div>
      </div>

    </div>

    <noData v-show="!loading && list.length === 0" title="暂未获取到您的该日清单信息" />
    <van-loading v-show="loading" class="loading" type="spinner" color="#1989fa" />
    <van-calendar v-model="showCalendar" :min-date="minDate" :max-date="maxDate" :show-confirm="false" color="#1989fa"
      :style="{ height: '70vh' }" @confirm="onConfirm" />
  </div>
</template>
<script>
import SelectInpatient from '@/components/selectInpatient'
import inpatientMinxins from './mixins'
export default {
  name: 'ZhuYuanRiQingDan',
  components: {
    SelectInpatient
  },
  mixins: [inpatientMinxins],
  data() {
    return {
      minDate: new Date('2020/1/1'),
      maxDate: new Date(),
      showCalendar: false,
      date: '',
      dayExpenditureMoney: '',
      list: [],
      loading: false
    }
  },
  created() {
    this.date = this.formatDate(new Date())
  },
  mounted() { },
  methods: {
    formatDate(date) {
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
    },
    onConfirm(date) {
      this.showCalendar = false
      this.date = this.formatDate(date)
      this.getData()
    },
    getData() {
      this.loading = true
      if (!this.admissionNum) {
        this.loading = false
        return
      }
      const params = {
        admissionNum: this.admissionNum,
        operDate: this.date,
        // operDate: '2023-8-14',
        patientZyName: this.patientZyName
      }
      this.$Apis.getZyInspectionReportInfo(params).then(res => {
        if (res.code === 200) {
          this.dayExpenditureMoney = res.data.money || ''
          this.list = res.data.reportRes || []
          console.log('this.dayExpenditureMoney')
          console.log(this.dayExpenditureMoney)
        } else {
          this.list = []
          this.$toast.fail(res.message)
        }
        this.loading = false
      }).catch(() => {
        this.list = []
        this.loading = false
      })
    }

  }
}
</script>
<style lang="less" scoped>
.text-box{
  display: flex;
  justify-content: space-between;
  margin:0 .30rem;
  .text {
    padding: 0 0 0 .15rem;
    font-size: .14rem;
    color: #555;
    margin: .15rem 0;
    font-weight: bold;
  }

  .price {
    font-size: .22rem;
    color: #ff9241;
    font-weight: normal;
  }

}
.zhuyuanri-xiangqing {
  position: relative;
  height: 100vh;

  .title {
    margin-top: .15rem;
    border-top: 1px solid #fff;
    text-align: center;
    color: #FFFFFF;
    padding: .2rem 0 .05rem;

    .iconclass {
      color: #FFFFFF;
      transform: rotateZ(90deg);
      padding-right: .06rem;
    }
  }

  .bottom-box {
    padding-bottom: .2rem;
  }

  .list-box {
    margin: .15rem .15rem 0 .15rem;
    padding: .03rem .1rem .1rem;
  }
   .bottom-title{
    display: flex;
    justify-content: space-between;
    background: #fff;
    padding: .08rem;
    margin-bottom: .12rem;
   }
  .text-bottom {
    .price-size {
      color: red;
    }
  }

  .list-wrap {
    color: #888;
    display: flex;
    border-bottom: 1px solid #ececec;
    padding: .04rem 0;
    background: #fff;

    .list-item {
      padding: .1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      word-break: break-all;
      line-height: 1.2;
    }

    .flex4 {
      flex: 4;
    }

    .flex3 {
      flex: 3;
    }

    .flex2 {
      flex: 2;
    }
  }

  .list-wrap:last-child {
    border-bottom: 0;
  }

  .list-wrap2 {
    // margin: 0;
  }

  .loading {
    width: 100%;
    height: calc(100vh - 2rem);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}</style>
