<template>
  <div class="select-inpatient">
    <div class="select-inpatient-list">
      <div class="select-inpatient-item">
        <div class="item-left">
          <img src="@/assets/images/default.png" alt="">
        </div>
        <div class="item-right">
          <p v-if="!item.admissionNum" class="item-right-no">暂无住院人</p>
          <p v-if="item.patientZyName" class="item-right-pople">
            住院人：{{ item.patientZyName }}
          </p>
          <div v-if="item.admissionNum" class="item-right-num">
            住院号：{{ item.admissionNum }}
          </div>
        </div>
        <div class="item-button" @click="switchInpatient(item.admissionNum)">{{ item.admissionNum ? "切换" : "添加" }}住院人</div>
      </div>
      <slot name="date" />
    </div>
    <van-action-sheet v-model="show" title="选择住院人" close-on-click-action>
      <van-cell-group inset>
        <template v-for="(t, index) in inpatientList">
          <van-cell :key="t.id" center :title="`住院人：${t.patientZyName}`" :label="`住院号：${t.admissionNum}`" @click="selectChange(t, index)">
            <template #icon>
              <van-icon class="mr5" name="passed" size="26" :color="activeNum === t.admissionNum ? '#3077fe' : ''" />
            </template>
            <template #right-icon>
              <van-icon class="mr5" size="26" name="edit" @click.stop="edit(t.id)" />
              <van-icon v-if="activeNum !== t.admissionNum" size="26" name="delete-o" @click.stop="deleteInpatient(t.id)" />
            </template>
          </van-cell>
        </template>
        <van-cell center title="添加住院人" :label="`还可以添加${4-inpatientList.length}人`" is-link @click="inpatientDeit" />
      </van-cell-group>
    </van-action-sheet>
  </div>
</template>
<script>
export default {
  name: 'SelectInpatient',
  data() {
    return {
      id: '',
      item: {},
      inpatientList: [],
      admissionNum: '',
      patientZyName: '',
      activeNum: 0,
      show: false
    }
  },
  created() {
    const patientZyName = localStorage.getItem('patientZyName')
    const admissionNum = localStorage.getItem('admissionNum')
    const patientIdHis = localStorage.getItem('patientIdHis')
    const patientZySfzh = localStorage.getItem('patientZySfzh')
    if (admissionNum) {
      this.patientZyName = patientZyName
      this.admissionNum = admissionNum
      this.activeNum = admissionNum
      const item = { patientZyName, admissionNum, patientIdHis, patientZySfzh }
      this.item = item
      this.$emit('selectChange', item)
    }
    const userInfo = this.$store.state.userInfo
    if (userInfo) {
      this.id = userInfo.id
      this.getInpatientList()
    }
  },
  mounted() {

  },
  methods: {
    getInpatientList() {
      this.$Apis.getPatientZyBindingList(this.id).then(res => {
        if (res.code === 200) {
          this.inpatientList = res.data
          if (!this.admissionNum) {
            this.selectChange(this.inpatientList[0])
          }
        } else {
          this.inpatientList = []
        }
      })
    },
    inpatientDeit() {
      if (this.inpatientList.length >= 4) return
      this.show = false
      this.$router.push('/inpatient-edit')
    },
    switchInpatient(admissionNum) {
      if (admissionNum) {
        this.show = true
      } else {
        this.inpatientDeit()
      }
    },
    selectChange(item) {
      this.show = false
      this.activeNum = item.admissionNum
      localStorage.patientZyName = item.patientZyName
      localStorage.admissionNum = item.admissionNum
      localStorage.patientIdHis = item.patientIdHis
      localStorage.patientZySfzh = item.patientZySfzh
      this.item = item
      this.$emit('selectChange', item)
    },
    edit(id) {
      this.$router.push(`/inpatient-edit?id=${id}`)
    },
    deleteInpatient(id) {
      this.$dialog.alert({
        title: '标题',
        message: '确定删除改住院人？',
        showCancelButton: true
      }).then(() => {
        this.delPatient(id)
      }).catch(() => {})
    },
    delPatient(id) {
      this.$Apis.delPatientZyBindingByID(id).then(res => {
        if (res.code === 200) {
          this.$toast.success(res.message || '删除成功')
          this.getInpatientList()
        } else {
          this.$toast.fail(res.message || '删除失败')
        }
      })
    }
  }
}
</script>
<style rel="stylesheet/less" lang="less" scoped>
@fontSize: .16rem;
.select-inpatient {
  min-height: .7rem;
  margin: 0 .15rem;
  &-list {
    border-radius: .1rem;
    // background-color: #3077fe;
    background: url('../assets/images/inpatient-bj.png') center center no-repeat;
    background-size: 100% 100%;
    padding: 0.15rem 0.15rem;
    border-bottom: 1px solid #eee;
  }
  &-item {
    display: flex;
    .item-left {
      height: .56rem;
      width: .56rem;
      background-color: #fff;
      border-radius: 50%;
      margin: .06rem 0.1rem 0 0;
      img{
        height: 100%;
        width: 100%;
      }
    }
    .item-right {
      flex-grow: 1;
      &-name {
        font-size: @fontSize;
        line-height: 0.3rem;
        color: #333;
        span {
          display: inline-block;
          margin-left: 0.05rem;
          font-size: @fontSize;
          line-height: 0.1rem;
          padding: 0.04rem;
          color: #eb9797;
          border: 1px solid #eb9797;
        }
      }
      &-pople{
        font-size: .18rem;
        color: #fff;
        line-height: 1.5;
        margin: .06rem 0 .04rem;
      }
      &-num {
        display: inline-block;
        border: 1px solid #fff;
        padding: 0 .05rem;
        border-radius: .2rem;
        font-size: .12rem;
        color: #fff;
        line-height: 2;
        &-red {
          color: #eb9797;
        }
      }
      &-no{
        font-size: @fontSize;
        color: #fff;
        line-height: .46rem;
      }
    }
    .item-button {
      display: flex;
      align-items: center;
      // border: 1px solid #fff;
      color: #fff;
      line-height: .3rem;
      height: .3rem;
      padding: 0 .1rem;
      border-radius: .2rem;
      margin-top: .16rem;
      background: linear-gradient(0deg,#ffdd56 0%, #ff8f00 100%);
    }
  }
  .mr5{
    margin-right: .1rem;
  }
}
</style>
